import React, { useCallback, useRef, useState } from 'react';
import { FiLogIn, FiPhone, FiLock } from 'react-icons/fi';
import { FormHandles } from '@unform/core';

import { Form } from '@unform/web';
import * as Yup from 'yup';

import { Link } from 'react-router-dom';
import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';

import getValidationErrors from '../../utils/getValidationErrors';

import logoImg from '../../assets/logo.svg';

import Input from '../../components/Input';
import Button from '../../components/Button';

import { Container, Content, AnimationContainer, Background } from './styles';

interface SignInFormData {
  mobile: number;
  password: string;
}

const SignIn: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);

  const { signIn } = useAuth();
  const { addToast } = useToast();

  const handleSubmit = useCallback(
    async (data: SignInFormData) => {
      let isMounted = true;
      try {
        if (isMounted) {
          setLoading(true);

          formRef.current?.setErrors({});

          const schema = Yup.object().shape({
            mobile: Yup.string().min(
              10,
              'Informe o número do celular com ddd.',
            ),
            password: Yup.string().min(
              6,
              'Senha obrigatória, mínimo 6 caracteres.',
            ),
          });

          await schema.validate(data, {
            abortEarly: false,
          });

          await signIn({
            mobile: data.mobile,
            password: data.password,
          });

          const token = localStorage.getItem('@Massas:token');

          if (!token) {
            throw new Error('Error from api.');
          }
        }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: 'Erro na autenticação',
          description: 'Ocorreu erro ao fazer logon, cheque suas credenciais.',
        });
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
      return () => {
        isMounted = false;
      };
    },
    [signIn, addToast],
  );

  return (
    <Container>
      <Content>
        <AnimationContainer>
          <img src={logoImg} alt="Massas-Trigo" />

          <Form ref={formRef} onSubmit={handleSubmit}>
            <h1>Faça seu logon</h1>

            <Input
              name="mobile"
              icon={FiPhone}
              placeholder="Número do celular"
            />

            <Input
              name="password"
              icon={FiLock}
              type="password"
              autoComplete="current-password"
              placeholder="Senha"
            />

            <Button type="submit" loading={loading}>
              Entrar
            </Button>

            <Link to="/forgot-password">Esqueci minha senha</Link>
          </Form>

          <Link to="/signup">
            <FiLogIn />
            Criar conta
          </Link>
        </AnimationContainer>
      </Content>
      <Background />
    </Container>
  );
};

export default SignIn;
